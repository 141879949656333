/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

const Page = ({ data }) => {
  const { wpPage: page } = data;
  const { seo, pageSettings } = page;

  return (
    <Layout
      seo={seo}
      inner
      schemaCodes={pageSettings.schemaCodes}
      contact
    ></Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      pageSettings {
        header
        formTitle
        schemaCodes {
          singleSchema
        }
      }
    }
  }
`;
